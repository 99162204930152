import React, {useState} from 'react';
import {Button} from "@mui/material";
import {YouTubeDialog} from "@atttomyx/react-components";

const WatchDemoButton = (props) => {
    const {title, videoId, mode} = props;
    const [showDemo, setShowDemo] = useState(false);

    return <div className="watch-demo-button">
        <Button
            color="primary"
            size="large"
            onClick={() => setShowDemo(true)}
        >
            Watch demo
        </Button>
        {showDemo ?
            <YouTubeDialog
                title={title}
                videoId={videoId}
                mode={mode}
                onClose={() => setShowDemo(false)}
            /> : null}
    </div>
}

export default WatchDemoButton;
