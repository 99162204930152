import React, {useMemo} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {useQuery} from "@atttomyx/react-hooks";
import {NotFoundPage} from "@atttomyx/react-components";
import {Header} from "./components";
import {
    AcceptableUsePolicy,
    Dots,
    Home,
    PrivacyPolicy,
    Reveal,
    Schedule,
    Sudoku,
    UserTermsOfService,
    Words
} from "./pages";
import {
    MODE_INLINE,
    PAGE_ACCEPTABLE_USE_POLICY,
    PAGE_DOTS,
    PAGE_HOME,
    PAGE_PRIVACY_POLICY,
    PAGE_REVEAL,
    PAGE_SCHEDULE,
    PAGE_SUDOKU,
    PAGE_USER_TERMS_OF_SERVICE,
    PAGE_WORDS
} from "./constants";

const App = (props) => {
    const { dimensions } = props;
    const { wide } = dimensions;
    const query = useQuery();

    const inline = useMemo(() => {
        return MODE_INLINE === query.get("mode");
    }, [query]);

    return <div className="app">
        {!inline ? <Header wide={wide}/> : null}
        <div className="content">
            <Switch>
                <Route exact path="/">
                    <Home wide={wide}/>
                </Route>
                <Route path={PAGE_HOME}>
                    <Redirect to="/"/>
                </Route>
                <Route path={PAGE_SCHEDULE}>
                    <Schedule wide={wide}/>
                </Route>
                <Route path={PAGE_SUDOKU}>
                    <Sudoku inline={inline}/>
                </Route>
                <Route path={PAGE_WORDS}>
                    <Words inline={inline}/>
                </Route>
                <Route path={PAGE_DOTS}>
                    <Dots inline={inline}/>
                </Route>
                <Route path={PAGE_REVEAL}>
                    <Reveal inline={inline}/>
                </Route>
                <Route path={PAGE_USER_TERMS_OF_SERVICE}>
                    <UserTermsOfService/>
                </Route>
                <Route path={PAGE_ACCEPTABLE_USE_POLICY}>
                    <AcceptableUsePolicy/>
                </Route>
                <Route path={PAGE_PRIVACY_POLICY}>
                    <PrivacyPolicy/>
                </Route>
                <Route>
                    <NotFoundPage/>
                </Route>
            </Switch>
        </div>
    </div>
}

export default withRouter(App);
